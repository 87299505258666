import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Beaker, Menu, X, Wallet, Network } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface NavbarProps {
  onInvestorClick: () => void;
  onContactClick: () => void;
  onPartnerClick: () => void;
}

export default function Navbar({ onInvestorClick, onContactClick, onPartnerClick }: NavbarProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigation = (to: string, callback?: () => void) => {
    setIsMenuOpen(false);
    if (callback) {
      callback();
    } else {
      navigate(to);
    }
  };

  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/technologies", label: "Technologies" },
    { to: "/products", label: "Products" },
    { to: "/case-studies", label: "Case Studies" },
    { to: "/about", label: "About" },
  ];

  const isActive = (path: string) => {
    if (path === "/" && location.pathname !== "/") return false;
    return location.pathname.startsWith(path);
  };

  return (
    <motion.nav
      className={`fixed w-full z-50 transition-colors duration-300 ${
        isScrolled ? 'bg-[#0A0A0B]/95 backdrop-blur-md' : 'bg-transparent'
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="relative group"
          >
            <Link 
              to="/" 
              className="flex items-center"
              onClick={(e) => {
                e.preventDefault();
                navigate('/');
              }}
            >
              {/* Glow effect behind the beaker */}
              <div className="absolute inset-0 bg-[#00FFFF]/20 rounded-full blur-xl 
                group-hover:blur-2xl group-hover:scale-150 transition-all duration-300" />
              
              {/* Animated beaker */}
              <motion.div
                animate={{
                  rotate: [0, 5, -5, 0],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="relative"
              >
                <Beaker className="h-8 w-8 text-[#00FFFF] drop-shadow-[0_0_10px_rgba(0,255,255,0.5)]
                  group-hover:text-white group-hover:drop-shadow-[0_0_15px_rgba(0,255,255,0.8)]
                  transition-all duration-300" />
                
                {/* Bubbles effect */}
                <motion.div
                  className="absolute bottom-0 left-1/2 w-1 h-1 bg-[#00FFFF] rounded-full opacity-0
                    group-hover:opacity-100 transition-opacity duration-300"
                  animate={{
                    y: [-20, -30],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatDelay: 0.5,
                  }}
                />
                <motion.div
                  className="absolute bottom-0 left-1/4 w-1 h-1 bg-[#00FFFF] rounded-full opacity-0
                    group-hover:opacity-100 transition-opacity duration-300"
                  animate={{
                    y: [-15, -25],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 1.2,
                    repeat: Infinity,
                    repeatDelay: 0.3,
                  }}
                />
              </motion.div>
            </Link>
          </motion.div>
          
          <div className="hidden md:flex items-center space-x-1">
            {navLinks.map((link) => (
              <motion.div
                key={link.to}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  to={link.to}
                  className={`text-gray-300 hover:text-white px-4 py-2 rounded-full text-sm 
                    transition-colors duration-200 ${isActive(link.to) ? 'text-white font-medium' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(link.to);
                  }}
                >
                  {link.label}
                </Link>
              </motion.div>
            ))}
            
            {/* DAO Button */}
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Link
                to="/dao"
                className={`flex items-center bg-gradient-to-r from-purple-600/30 to-indigo-600/30 
                text-white hover:from-purple-600/50 hover:to-indigo-600/50 px-4 py-2 rounded-full text-sm 
                transition-all duration-200 font-medium ${isActive('/dao') ? 'from-purple-600/50 to-indigo-600/50' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/dao');
                }}
              >
                <Network className="h-4 w-4 mr-1 text-purple-300" />
                DAO
              </Link>
            </motion.div>
            
            {/* Contact Us Button */}
            <motion.button
              onClick={() => handleNavigation('', onContactClick)}
              className="text-white hover:text-[#00FFFF] px-4 py-2 rounded-full text-sm 
                transition-colors duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Contact Us
            </motion.button>
            
            {/* Partner With Us Button */}
            <motion.button
              onClick={() => handleNavigation('', onPartnerClick)}
              className="text-white bg-gradient-to-r from-[#8A2BE2]/50 to-[#4B0082]/50 hover:from-[#8A2BE2] hover:to-[#4B0082]
                px-5 py-2 rounded-full text-sm transition-all duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Partner With Us
            </motion.button>
            
            {/* Connect to Govern Button */}
            <motion.button
              onClick={() => handleNavigation('', onInvestorClick)}
              className="ml-2 text-[#00FFFF] border border-[#00FFFF]/30 hover:border-[#00FFFF] 
                px-5 py-2 rounded-full text-sm transition-all duration-200 flex items-center"
              whileHover={{ scale: 1.05, backgroundColor: 'rgba(0, 255, 255, 0.1)' }}
              whileTap={{ scale: 0.95 }}
            >
              <Wallet className="w-4 h-4 mr-2" />
              <span>Connect to Govern</span>
            </motion.button>
          </div>
          
          <div className="md:hidden">
            <motion.button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-white p-2"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </motion.button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="md:hidden absolute top-full left-0 right-0 bg-[#0A0A0B]/95 backdrop-blur-md"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-4 py-4 space-y-2">
              {navLinks.map((link) => (
                <motion.div
                  key={link.to}
                  whileHover={{ x: 10 }}
                >
                  <Link
                    to={link.to}
                    className={`block text-gray-300 hover:text-white px-4 py-3 rounded-lg 
                      hover:bg-white/5 transition-colors duration-200 ${isActive(link.to) ? 'text-white bg-white/5' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                      navigate(link.to);
                    }}
                  >
                    {link.label}
                  </Link>
                </motion.div>
              ))}
              
              {/* DAO Link - Mobile */}
              <motion.div whileHover={{ x: 10 }}>
                <Link
                  to="/dao"
                  className={`flex items-center px-4 py-3 rounded-lg text-purple-300
                    bg-gradient-to-r from-purple-900/20 to-indigo-900/20 hover:from-purple-900/30 hover:to-indigo-900/30
                    transition-colors duration-200 ${isActive('/dao') ? 'from-purple-900/30 to-indigo-900/30' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsMenuOpen(false);
                    navigate('/dao');
                  }}
                >
                  <Network className="h-4 w-4 mr-2 flex-shrink-0" />
                  <span>DAO</span>
                </Link>
              </motion.div>
              
              {/* Contact Us Button - Mobile */}
              <motion.button
                onClick={() => handleNavigation('', onContactClick)}
                className="block w-full text-left px-4 py-3 rounded-lg 
                  hover:bg-white/5 transition-colors duration-200"
                whileHover={{ x: 10 }}
              >
                Contact Us
              </motion.button>
              
              {/* Partner With Us Button - Mobile */}
              <motion.button
                onClick={() => handleNavigation('', onPartnerClick)}
                className="block w-full text-left px-4 py-3 rounded-lg 
                  hover:bg-[#8A2BE2]/10 text-[#8A2BE2] transition-colors duration-200"
                whileHover={{ x: 10 }}
              >
                Partner With Us
              </motion.button>
              
              {/* Connect to Govern - Mobile */}
              <motion.button
                onClick={() => handleNavigation('', onInvestorClick)}
                className="flex items-center w-full text-left text-[#00FFFF] px-4 py-3 rounded-lg 
                  hover:bg-[#00FFFF]/5 transition-colors duration-200"
                whileHover={{ x: 10 }}
              >
                <Wallet className="w-4 h-4 mr-2 flex-shrink-0" />
                <span>Connect to Govern</span>
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
}