import React, { useEffect, useState } from 'react';
import { DashboardHeader } from './Dashboard/DashboardHeader';
import { WelcomeSection } from './Dashboard/WelcomeSection';
import { FinancialMetrics } from './Dashboard/FinancialMetrics';
import { DocumentsList } from './Dashboard/DocumentsList';
import { CompanyUpdates } from './Dashboard/CompanyUpdates';
import { InvestorEvents } from './Dashboard/InvestorEvents';
import { AIAgentsSection } from './Dashboard/AIAgents/AIAgentsSection';
import AdminDashboard from '../Admin/AdminDashboard';
import { supabase } from '../../utils/supabase';
import { motion } from 'framer-motion';
import { User, ChevronDown, File, PieChart, FileText, Users, Settings, ExternalLink } from 'lucide-react';

interface InvestorDashboardProps {
  isAdmin?: boolean;
  walletAddress?: string | null;
}

interface InvestorProfile {
  full_name: string;
  company_name: string;
}

export default function InvestorDashboard({ isAdmin = false, walletAddress = null }: InvestorDashboardProps) {
  const [showAdminDashboard, setShowAdminDashboard] = React.useState(false);
  const [investorProfile, setInvestorProfile] = useState<InvestorProfile | null>(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  // Helper function to safely format wallet addresses
  const formatWalletAddress = (address: string | null): string => {
    if (!address || typeof address !== 'string') {
      return 'Unknown';
    }
    
    if (address.startsWith('DemoWallet')) {
      return `${address} (Demo)`;
    }
    
    return address.length > 12 
      ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
      : address;
  };

  useEffect(() => {
    fetchInvestorProfile();
  }, [walletAddress]);

  async function fetchInvestorProfile() {
    setLoading(true);
    try {
      // Check if we have a valid wallet address to use
      if (!walletAddress || typeof walletAddress !== 'string') {
        console.error('Invalid wallet address provided:', walletAddress);
        throw new Error('Invalid wallet address');
      }
      
      // In a real app, this would fetch actual data from an API
      // For now, we'll create mock data
      const isDemoWallet = walletAddress.startsWith('DemoWallet');
      
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const mockProfile = {
        name: isDemoWallet ? 'Demo Investor' : 'Verified Investor',
        walletAddress: walletAddress,
        tokenBalance: Math.floor(Math.random() * 100000) + 1000,
        tokenPrice: 0.12 + (Math.random() * 0.05),
        portfolioValue: 0,
        allocationDate: '2023-05-15',
        transactions: [
          {
            id: 1,
            type: 'Purchase',
            amount: 5000,
            date: '2023-06-01',
            status: 'Completed'
          },
          {
            id: 2, 
            type: 'Staking Reward',
            amount: 250,
            date: '2023-07-01',
            status: 'Completed'
          },
          {
            id: 3,
            type: 'Vesting',
            amount: 2500,
            date: '2023-08-01',
            status: 'Pending'
          }
        ],
        vestingSchedule: {
          total: 10000,
          released: 7500,
          nextRelease: '2023-09-01',
          nextAmount: 2500
        }
      };
      
      // Calculate portfolio value
      mockProfile.portfolioValue = mockProfile.tokenBalance * mockProfile.tokenPrice;
      
      setProfile(mockProfile);
    } catch (error) {
      console.error('Error fetching investor profile:', error);
      
      // Provide a fallback profile for development/error cases
      setProfile({
        name: 'Error Loading Profile',
        walletAddress: walletAddress || 'Unknown',
        tokenBalance: 0,
        tokenPrice: 0.15,
        portfolioValue: 0,
        allocationDate: 'Unknown',
        transactions: [],
        vestingSchedule: { total: 0, released: 0, nextRelease: 'N/A', nextAmount: 0 }
      });
    } finally {
      setLoading(false);
    }
  }

  // Helper to format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  // Generate tab content based on active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-[#121214] p-5 rounded-xl border border-white/10">
                <p className="text-gray-400 text-sm">Token Balance</p>
                <p className="text-2xl font-bold text-white mt-2">{profile?.tokenBalance.toLocaleString()} SELF</p>
              </div>
              <div className="bg-[#121214] p-5 rounded-xl border border-white/10">
                <p className="text-gray-400 text-sm">Token Price</p>
                <p className="text-2xl font-bold text-white mt-2">{formatCurrency(profile?.tokenPrice || 0)}</p>
              </div>
              <div className="bg-[#121214] p-5 rounded-xl border border-white/10">
                <p className="text-gray-400 text-sm">Portfolio Value</p>
                <p className="text-2xl font-bold text-white mt-2">{formatCurrency(profile?.portfolioValue || 0)}</p>
              </div>
            </div>

            <div className="bg-[#121214] p-6 rounded-xl border border-white/10">
              <h3 className="text-lg font-semibold text-white mb-4">Token Allocation</h3>
              <div className="mb-6">
                <p className="text-gray-400 text-sm mb-2">Initial Allocation Date</p>
                <p className="text-white">{profile?.allocationDate}</p>
              </div>
              
              <h4 className="text-md font-semibold text-white mb-3">Vesting Schedule</h4>
              <div className="mb-4">
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-400">Released</span>
                  <span className="text-white">{profile?.vestingSchedule.released.toLocaleString()} SELF ({Math.round((profile?.vestingSchedule.released / profile?.vestingSchedule.total) * 100)}%)</span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-2">
                  <div 
                    className="bg-[#00FFFF] h-2 rounded-full" 
                    style={{ width: `${(profile?.vestingSchedule.released / profile?.vestingSchedule.total) * 100}%` }}
                  ></div>
                </div>
              </div>
              
              <div className="p-4 bg-black/50 rounded-lg border border-white/5">
                <div className="flex justify-between mb-1">
                  <span className="text-gray-400 text-sm">Next Release</span>
                  <span className="text-white text-sm">{profile?.vestingSchedule.nextRelease}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400 text-sm">Amount</span>
                  <span className="text-white text-sm">{profile?.vestingSchedule.nextAmount.toLocaleString()} SELF</span>
                </div>
              </div>
            </div>
            
            <div className="bg-[#121214] p-6 rounded-xl border border-white/10">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-white">Recent Transactions</h3>
                <button className="text-[#00FFFF] text-sm">View All</button>
              </div>
              
              <div className="space-y-4">
                {profile?.transactions.map((tx: any) => (
                  <div key={tx.id} className="p-4 bg-black/50 rounded-lg border border-white/5">
                    <div className="flex justify-between mb-2">
                      <span className="text-white font-medium">{tx.type}</span>
                      <span className={`font-medium ${tx.type === 'Purchase' ? 'text-green-400' : 'text-[#00FFFF]'}`}>
                        {tx.amount.toLocaleString()} SELF
                      </span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-400">{tx.date}</span>
                      <span className={tx.status === 'Completed' ? 'text-green-400' : 'text-yellow-400'}>
                        {tx.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      case 'documents':
        return (
          <div className="bg-[#121214] p-6 rounded-xl border border-white/10">
            <h3 className="text-lg font-semibold text-white mb-4">Investor Documents</h3>
            <div className="space-y-4">
              <div className="p-4 flex items-center justify-between bg-black/50 rounded-lg border border-white/5">
                <div className="flex items-center">
                  <div className="p-2 bg-blue-500/10 rounded-lg mr-3">
                    <FileText className="w-5 h-5 text-blue-400" />
                  </div>
                  <div>
                    <p className="text-white font-medium">SELF Token Purchase Agreement</p>
                    <p className="text-gray-400 text-sm">PDF • 2.4 MB • Uploaded Jun 01, 2023</p>
                  </div>
                </div>
                <button className="text-[#00FFFF] hover:underline text-sm flex items-center">
                  <span>Download</span>
                  <ExternalLink className="w-3 h-3 ml-1" />
                </button>
              </div>
              
              <div className="p-4 flex items-center justify-between bg-black/50 rounded-lg border border-white/5">
                <div className="flex items-center">
                  <div className="p-2 bg-blue-500/10 rounded-lg mr-3">
                    <FileText className="w-5 h-5 text-blue-400" />
                  </div>
                  <div>
                    <p className="text-white font-medium">Vesting Schedule Details</p>
                    <p className="text-gray-400 text-sm">PDF • 1.1 MB • Uploaded Jun 15, 2023</p>
                  </div>
                </div>
                <button className="text-[#00FFFF] hover:underline text-sm flex items-center">
                  <span>Download</span>
                  <ExternalLink className="w-3 h-3 ml-1" />
                </button>
              </div>
              
              <div className="p-4 flex items-center justify-between bg-black/50 rounded-lg border border-white/5">
                <div className="flex items-center">
                  <div className="p-2 bg-purple-500/10 rounded-lg mr-3">
                    <PieChart className="w-5 h-5 text-purple-400" />
                  </div>
                  <div>
                    <p className="text-white font-medium">Q2 2023 Investor Report</p>
                    <p className="text-gray-400 text-sm">PDF • 3.8 MB • Uploaded Jul 10, 2023</p>
                  </div>
                </div>
                <button className="text-[#00FFFF] hover:underline text-sm flex items-center">
                  <span>Download</span>
                  <ExternalLink className="w-3 h-3 ml-1" />
                </button>
              </div>
            </div>
          </div>
        );
      case 'settings':
        return (
          <div className="bg-[#121214] p-6 rounded-xl border border-white/10">
            <h3 className="text-lg font-semibold text-white mb-4">Account Settings</h3>
            
            <div className="mb-6">
              <p className="text-gray-400 text-sm mb-2">Connected Wallet</p>
              <div className="flex items-center">
                <span className="text-white font-mono">{walletAddress}</span>
              </div>
            </div>
            
            <div className="mb-6">
              <p className="text-gray-400 text-sm mb-2">Notification Preferences</p>
              <div className="space-y-3">
                <div className="flex items-center">
                  <input type="checkbox" id="emailNotify" className="mr-3" checked />
                  <label htmlFor="emailNotify" className="text-white">Email notifications</label>
                </div>
                <div className="flex items-center">
                  <input type="checkbox" id="vestingNotify" className="mr-3" checked />
                  <label htmlFor="vestingNotify" className="text-white">Vesting event reminders</label>
                </div>
                <div className="flex items-center">
                  <input type="checkbox" id="reportNotify" className="mr-3" checked />
                  <label htmlFor="reportNotify" className="text-white">New report notifications</label>
                </div>
              </div>
            </div>
            
            <div>
              <button className="px-4 py-2 bg-[#00FFFF] text-black font-medium rounded-lg hover:bg-[#00FFFF]/90 transition-colors duration-200">
                Save Changes
              </button>
            </div>
          </div>
        );
      default:
        return <div>Tab content not found</div>;
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <DashboardHeader 
        isAdmin={isAdmin} 
        onAdminClick={() => setShowAdminDashboard(true)} 
        walletAddress={walletAddress}
      />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-8">
          <WelcomeSection investorName={investorProfile?.full_name || 'Investor'} />
          
          <div>
            <h2 className="text-2xl font-bold text-white mb-6">Company Analytics</h2>
            <FinancialMetrics />
          </div>
          
          <AIAgentsSection />

          <div className="grid lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 space-y-8">
              <DocumentsList />
            </div>
            <div className="space-y-8">
              <CompanyUpdates />
              <InvestorEvents />
            </div>
          </div>
        </div>
      </main>

      {showAdminDashboard && isAdmin && (
        <AdminDashboard onClose={() => setShowAdminDashboard(false)} />
      )}
    </div>
  );
}